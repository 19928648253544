import { Inject, Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { THEMES_QUERY } from "../fragments/themes";
import { themes } from "../../../generated/themes";
import { map, Observable, ReplaySubject, take, tap } from "rxjs";
import { Theme } from "../../../generated/Theme";
import { DOCUMENT } from "@angular/common";

@Injectable({ providedIn: 'root' })
export class ThemeService {

  private readonly themes = new ReplaySubject<Theme[]>(1);
  private loadedThemesInitially = false;

  constructor(
    private apollo: Apollo,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public getThemes(): Observable<Theme[]> {
    if (!this.loadedThemesInitially) {
      this.loadThemes();
      this.loadedThemesInitially = true;
    }

    return this.themes;
  }

  public getThemeById(id: number): Observable<Theme | undefined> {
    return this.getThemes()
      .pipe(
        map((themes) => themes.find((theme) => theme.id === id))
      )
  }

  public getSelectedTheme(): Observable<Theme | undefined> {
    return this.getThemes()
      .pipe(
        take(1),
        map((themes) => {
          if (themes.length) {
            return themes[0];
          }
        })
      );
  }

  private loadThemes(): void {
    this.apollo.query<themes>({
      query: THEMES_QUERY,
      fetchPolicy: 'network-only'
    }).pipe(
      map((response) => response.data!.themes || []),
      tap((themes) => {
        this.themes.next(themes)

        if (themes.length) {
          this.setCssPropertiesFromTheme(themes[0]);
        }
    })
    ).subscribe();
  }

  private setCssPropertiesFromTheme(theme: Theme): void {
    this.document.documentElement.style.setProperty('--header-color', theme.headerColor);
    this.document.documentElement.style.setProperty('--header-link-color', theme.headerLinkColor);
    this.document.documentElement.style.setProperty('--header-active-link-color', theme.headerActiveLinkColor);
    this.document.documentElement.style.setProperty('--header-border-color', theme.headerBorderColor);
    this.document.documentElement.style.setProperty('--color-primary', theme.primaryColor);
    this.document.documentElement.style.setProperty('--color-secondary', theme.secondaryColor);
  }
}
